import Vue from 'vue'
import axios from 'axios'

export function listaCientes() {
    return axios.get('/clientes.json').then(function (response) {
        return response.data;
    })
}

// Familias
export function getFamilias() {
    return axios.get('/familias/combo.json').then(function (response) {
        return response.data;
    })
}

export function getFamiliaPorId(id) {
    return axios.get('/familias/' + id + '.json').then(function (response) {
        return response.data;
    })
}

export function getFamiliaPorCodigo(codigo) {
    return axios.get('/familias/busca_codigo/' + codigo + '.json').then(function (response) {
        return response.data;
    })
}

// Entregas
export function listaEntregas(cliente, pendientes = 0) {
    return axios.get('/entregas/filtra_clientes.json?idcliente=' + cliente + '&pendientes=' + pendientes).then(function (response) {
        return response.data;
    })
}

export function cuentaEntregas(cliente, pendientes = 0) {
    return axios.get('/entregas/filtra_clientes.json?idcliente=' + cliente + '&pendientes=' + pendientes).then(function (response) {
        return response.data;
    })
}

// Busqueda articulos
export function buscaarticuloscodigo(codigo) {
    return axios.get('/articulos/busca_codigo.json?filtro=' + codigo).then(function (response) {
        return response.data;
    })
}

export function buscaarticulosdescripcion(descripcion) {
    return axios.get('/articulos/busca_descripcion.json?filtro=' + descripcion).then(function (response) {
        return response.data;
    })
}

export function buscaarticulosmodelo(modelo) {
    return axios.get('/articulos/busca_modelo.json?filtro=' + modelo).then(function (response) {
        return response.data;
    })
}

export function buscaarticulosfamilia(familia) {
    return axios.get('/articulos/busca_familia.json?filtro=' + familia).then(function (response) {
        return response.data;
    })
}

// Busqueda clientes
export function buscaclientesnombre(nombre) {
    return axios.get('/clientes/busca_nombre.json?nombre=' + nombre).then(function (response) {
        return response.data;
    })
}

export function buscaclientesdni(dni) {
    return axios.get('/clientes/busca_dni.json?dni=' + dni).then(function (response) {
        return response.data;
    })
}

export function buscaclientesid(id) {
    return axios.get('/clientes/' + id + '.json').then(function (response) {
        return response.data;
    }).catch(error => console.log(error))
}

export function logout() {
    return axios.delete('/usuarios/logout').then(function (response) {
        return response.data;
    })
}

// Busqueda presupuestos
export function buscapresupuestoid(id) {
    return axios.get('/presupuestos/' + id + '.json').then(function (response) {
        return response.data;
    })
}

export function buscapresupuesto(numero) {
    return axios.get('/presupuestos/busca_numero.json?numero=' + numero).then(function (response) {
        return response.data;
    })
}

export function buscapptocliente(cliente) {
    return axios.get('/presupuestos/busca_cliente.json?cliente=' + cliente).then(function (response) {
        return response.data;
    })
}

// Busqueda albaranes
export function buscaalbaranid(id) {
    return axios.get('/albaranes/' + id + '.json').then(function (response) {
        return response.data;
    })
}

export function buscaalbaran(numero) {
    return axios.get('/albaranes/busca_numero.json?numero=' + numero).then(function (response) {
        return response.data;
    })
}

export function buscaalbarancliente(cliente) {
    return axios.get('/albaranes/busca_cliente.json?cliente=' + cliente).then(function (response) {
        return response.data;
    })
}

export function buscaalbaranclienteparafactura(cliente) {
    return axios.get('/albaranes/busca_cliente_factura.json?cliente=' + cliente).then(function (response) {
        return response.data;
    })
}

export function getlineasalbaran(albaran_id) {
    return axios.get('/albaranes/' + albaran_id + '/lineas.json').then(function (response) {
        return response.data;
    })
}

// Busqueda de cajas
export function buscacajas(dias, fecha) {
    if (fecha == undefined) {
        return axios.get('/cajas/busca_cajas.json?dias=' + dias).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/cajas/busca_cajas.json?fecha=' + fecha + '&dias=' + dias).then(function (response) {
            return response.data;
        })
    }
}

// Busqueda lineascaja
export function buscalineascaja(caja) {
    return axios.get('/lineascaja/busca_caja.json?caja=' + caja).then(function (response) {
        return response.data;
    })
}

// Busqueda documentos
export function buscadocumento(tipo, numero) {
    var _cadena = "/" + tipo + "/busca_numero.json?numero=" + numero;
    // console.log(_cadena);
    return axios.get(_cadena).then(function (response) {
        return response.data;
    })
}

